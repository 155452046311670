// CourseList.js
import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Grid } from '@mui/material';

const CourseList = () => {
  const courseGroups = [
    { id: 'a', zh: 'A：', image: process.env.PUBLIC_URL + '/images/listening.png' },
    { id: 'b', zh: 'B：', image: process.env.PUBLIC_URL + '/images/reading.jpeg' },
    { id: 'c', zh: 'C：', image: process.env.PUBLIC_URL + '/images/writing.jpeg'},
    { id: 'd', zh: 'D：', image: process.env.PUBLIC_URL + '/images/psle.png' },
  ];

  const courses = [
    {
      id: 'a',
      content: [
        { zh: '听力', en: 'Listening' },
        { zh: '口试', en: 'Oral Examination' },
        { zh: '朗读', en: 'Reading Aloud' },
        { zh: '视频', en: 'Videos' },
        { zh: '会话', en: 'Conversations' },
      ],
    },
    {
      id: 'b',
      content: [
        { zh: '试卷题型训练', en: 'Exam Paper Skills Training' },
        { zh: '基础知识练习', en: 'Basic Knowledge Practice' },
        { zh: '便条', en: 'Notes' },
        { zh: '阅读理解分析', en: 'Reading Comprehension Analysis' },
      ],
    },
    {
      id: 'c',
      content: [
        { zh: '写作', en: 'Writing' },
        { zh: '开头结尾', en: 'Introductions and Conclusions' },
        { zh: '好词好句好段', en: 'Phrases and Paragraphs' },
        { zh: '成语', en: 'Idioms' },
        { zh: '俗语', en: 'Proverbs' },
        { zh: '看图作文', en: 'Picture Composition' },
        { zh: '命题作文', en: 'Composition Writing' },
        { zh: '完成文章', en: 'Completing Articles' },
      ],
    },
    {
      id: 'd',
      content: [
        { zh: 'PSLE真题训练', en: 'PSLE training' },
      ],
    },
  ];

  return (
    <Box sx={{ padding: '40px' }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', marginBottom: '30px' }}>
        Our Courses
      </Typography>
      {courseGroups.map((courseGroup) => (
        <Box key={courseGroup.id} sx={{ marginBottom: '20px' }}>
          <Grid container alignItems="center" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="center" spacing={2}>
            {/* Image on the left for mobile, right for larger screens */}
            <Grid item xs={12} md={4} order={{ xs: 2, md: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: { xs: '20px', md: 0 } }}>
                <img src={courseGroup.image} alt={`Image for ${courseGroup.en}`} style={{ width: '100%', maxWidth: '150px' }} />
              </Box>
            </Grid>

            {/* Course list */}
            <Grid item xs={12} md={8} order={{ xs: 1, md: 2 }}>
              <Typography variant="h6" gutterBottom>
                {courseGroup.en} {courseGroup.zh}
              </Typography>
              <List sx={{ backgroundColor: '#f0f0f0', borderRadius: '5px' }}>
                {courses
                  .find((course) => course.id === courseGroup.id)
                  .content.map((item, index) => (
                    <ListItem key={index} disableGutters>
                      <ListItemText
                        primaryTypographyProps={{ variant: 'body1', color: 'primary', fontWeight: 'bold' }}
                        primary={`${item.zh} - ${item.en}`}
                      />
                    </ListItem>
                  ))}
              </List>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default CourseList;