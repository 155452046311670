import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import TeacherBio from './TeacherBio';

const Banner = () => {
  return (
    <Box sx={{ backgroundColor: '#f0f0f0', padding: '40px 20px' }}>
      <Grid container alignItems="center" justifyContent="center" spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography variant="h3" align="center" gutterBottom>
            Xing Fan Learning Centre
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            星帆补习中心
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            Mandarin tuition services for varied levels of students
          </Typography>

          {/* Additional Information */}
          <Typography variant="body1" align="center" gutterBottom>
            <b>Address: Canberra Community Club 2 Sembawang Crescent #03-03C Singapore 757632 </b>
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: { xs: '20px', md: 0 } }}>
            <TeacherBio size={{ xs: '100px', md: '160px' }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Banner;
