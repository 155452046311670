import React from 'react';
import { Box, Typography, Grid, Avatar } from '@mui/material';

const Testimonials = () => {
  const testimonials = [
    { id: 1, image: process.env.PUBLIC_URL + '/images/testi01.jpeg' },
    //{ id: 2, image: process.env.PUBLIC_URL + '/images/testi02.jpeg' },
    { id: 3, image: process.env.PUBLIC_URL + '/images/testi03.jpeg' },
    //{ id: 4, image: process.env.PUBLIC_URL + '/images/testi04.jpeg' },
    { id: 5, image: process.env.PUBLIC_URL + '/images/testi05.jpeg' },
    { id: 6, image: process.env.PUBLIC_URL + '/images/testi06.jpeg' },
    { id: 7, image: process.env.PUBLIC_URL + '/images/testi07.jpeg' },
    { id: 8, image: process.env.PUBLIC_URL + '/images/testi08.jpeg' },
    { id: 9, image: process.env.PUBLIC_URL + '/images/testi09.jpeg' },
    { id: 10, image: process.env.PUBLIC_URL + '/images/testi10.jpeg' },
    // Add more testimonials here with their image URLs
  ];

  return (
    <Box sx={{ backgroundColor: '#f0f0f0', padding: '40px' }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
        Testimonials
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {testimonials.map((testimonial) => (
          <Grid item key={testimonial.id} xs={12} sm={6} md={4} lg={3}>
            <Avatar
              src={testimonial.image}
              alt={`Testimonial ${testimonial.id}`}
              sx={{ width: 120, height: 120, margin: '0 auto' }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Testimonials;
