// App.js
import React from 'react';
import './styles.css'; // Import your custom CSS styles here
import Header from './components/Header';
import Banner from './components/Banner';
import CourseList from './components/CourseList';
import Testimonials from './components/Testimonials';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import CourseSchedule from './components/CourseSchedule';

function App() {
  return (
    <div className="app">
      <Header />
      <main>
        <Banner />
        <CourseList />
        <CourseSchedule />
        <Testimonials />
        <ContactForm />
      </main>
      <Footer />
    </div>
  );
}

export default App;