import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#333', color: '#fff', padding: '20px', textAlign: 'center' }}>
      <Typography variant="body2">&copy; {new Date().getFullYear()} Xing Fan Learning Centre. All rights reserved.</Typography>
    </Box>
  );
};

export default Footer;
