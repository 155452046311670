import React from "react";
import {
  Box,
  Typography,
  Link,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper
} from "@mui/material";

const CourseSchedule = () => {
  const courseScheduleData = [
    { grade: "K2", tuitionSchedule: "星期六 Saturday 3pm-5pm" },
    { grade: "P1", tuitionSchedule: "星期日 Sunday 1pm-3pm" },
    { grade: "P2", tuitionSchedule: [
      "A班（Class A）星期一 Monday 5pm-7pm",
      "B班（Class B）星期六 Saturday 9am-11am"
    ]},
    { grade: "P3", tuitionSchedule: [
      "A班（Class A）星期四 Thursday 5:30pm-7:30pm",
      "B班（Class B）星期日 Sunday 3pm-5pm"
    ]},
    { grade: "P4", tuitionSchedule: [
      "A班（Class A）星期二 Tuesday 5pm-7pm",
      "B班（Class B）星期五 Friday 5pm-7pm",
      "C班（Class C）星期六 Saturday 11am-1pm"
    ]},
    { grade: "P5", tuitionSchedule: [
      "A班（Class A）星期二 Tuesday 7pm-9pm",
      "B班（Class B）星期日 Sunday 9am-11am"
    ]},
    { grade: "P6", tuitionSchedule: [
      "A班（Class A）星期一 Monday 7pm-9pm",
      "B班（Class B）星期六 Saturday 3pm-5pm"
    ]},
    { grade: "SCE1 (中一)", tuitionSchedule: [
      "星期五 Friday 7pm-9pm",
      "星期六 Saturday 1pm-3pm"
    ]},
    { grade: "SCE2 (中二)", tuitionSchedule: [
      "星期日 Sunday 11am-1pm"
    ]},
    { grade: "SCE3 (中三)", tuitionSchedule: [
      "A班（Class A）星期四 Thursday 7:30pm-9:30pm",
      "B班（Class B）星期六 Saturday 5pm-7pm"
    ]},
    { grade: "SCE4 (中四)", tuitionSchedule: [
      "星期五 Friday 7pm-9pm"
    ]}
  ];

  return (
    <Box sx={{ padding: "20px", overflowX: "auto" }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "center", marginBottom: "20px" }}
      >
        2025 Course Schedule
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300, textAlign: "center" }}>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Grade</b>
              </TableCell>
              <TableCell align="center">
                <b>Tuition Schedule</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courseScheduleData.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                  {row.grade}
                </TableCell>
                <TableCell align="center">
                {Array.isArray(row.tuitionSchedule) ? (
                <ul style={{ padding: 0, margin: 0, listStyleType: "none" }}>
                  {row.tuitionSchedule.map((schedule, idx) => (
                    <li key={idx}>
                      <Typography variant="body2" component="div">
                        {schedule}
                      </Typography>
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography variant="body2" component="div">
                  {row.tuitionSchedule}
                </Typography>
              )}    
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Download link for the PDF file */}
      <Box sx={{ textAlign: "center", marginTop: "20px" }}>
        <Link href="/course-schedule.pdf" target="_blank" download>
          Download Course Schedule (PDF)
        </Link>
      </Box>
    </Box>
  );
};

export default CourseSchedule;
