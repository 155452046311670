// CompanyLogo.js
import React from 'react';
import { Box, Avatar, Typography } from '@mui/material';

const CompanyLogo = () => {
  // You can replace the image URL with your company logo
  const companyLogoUrl = process.env.PUBLIC_URL + '/images/company-logo.png';

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
      <Avatar src={companyLogoUrl} alt="Company Logo" sx={{ width: '50px', height: '50px' }} />
      <Typography variant="h6" component="div" sx={{ marginLeft: '10px' }}>
        Xing Fan Learning Centre
      </Typography>
    </Box>
  );
};

export default CompanyLogo;