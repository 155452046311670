import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

const ContactForm = () => {
  // Form handling logic goes here

  // WhatsApp button click handler
  const handleWhatsAppClick = () => {
    const phoneNumber = '96873957';
    const message = 'Hello! I have a question.';

    // Generate WhatsApp message link
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // Open WhatsApp in a new tab
    window.open(whatsappLink, '_blank');
  };

  // Email button click handler
  const handleEmailClick = () => {
    const emailAddress = 'xinmo688@gmail.com';
    const subject = 'Question from Website';
    const body = 'Hello! I have a question.';

    // Generate email link
    const emailLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Open email client
    window.location.href = emailLink;
  };

  return (
    <Box sx={{ padding: '40px' }}>
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>
      <form>
        <TextField
          fullWidth
          label="Your Name"
          variant="outlined"
          margin="normal"
          // Add form handling props (e.g., value, onChange, etc.)
        />
        <TextField
          fullWidth
          label="Your Email"
          variant="outlined"
          margin="normal"
          // Add form handling props (e.g., value, onChange, etc.)
        />
        <TextField
          fullWidth
          label="Message"
          multiline
          rows={4}
          variant="outlined"
          margin="normal"
          // Add form handling props (e.g., value, onChange, etc.)
        />
        <Button variant="contained" color="primary" size="large" type="submit" onClick={handleEmailClick} startIcon={<EmailIcon />}>
          Email
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleWhatsAppClick}
          sx={{ marginLeft: '10px' }}
          startIcon={<WhatsAppIcon />}
        >
          WhatsApp
        </Button>
      </form>
    </Box>
  );
};

export default ContactForm;
